export default function CourseTitle({activeLesson, course, slug,lessonSlug,activeSubLesson}){

    return(
        <h1 key={activeLesson._id}>
            <span className="activeCourseTitle">
                <a href={`/kurs/${slug}`}>{course?.title}</a> &gt;&nbsp;
                <a href={`/kurs/${slug}/${lessonSlug}`}>{activeLesson?.title}</a> 
            </span>{activeSubLesson.title}
        </h1>
    )
}