import {useEffect} from "react"

export default function QuizCalculations({userQuizes, lesson, tqs, setTqs, tqq, setTqq}) {
        //Reduce the array with duplicate quiz ids:
        const uniqueUserQuizzes = [...new Map(userQuizes.map(q => [q.quiz, q])).values()]
        //Filter quisses for this course's lessons, and count correct answers:
        let singleQ = uniqueUserQuizzes.filter(quiz => quiz?.quiz.toString() === lesson?.quiz[0]?._id.toString())
        .map(quiz => JSON.parse(quiz?.quizscore)?.filter(corr => corr?.correct))
        .map((arr) => arr.length)
        
        let lql
        lesson?.quiz[0]?.questions.length !== undefined ? lql = lesson?.quiz[0]?.questions.length : lql = 0
        useEffect(() => {
            setTqs(s => Number(s) + Number(singleQ))
            setTqq(q => Number(q) + Number(lql))
        }, [])
        
        return(
            lesson.quiz.length > 0 ? 
            <fieldset className='quizbox'>
                <legend>{lesson?.quiz[0].title}</legend>
                <p className="currentScore">{!singleQ.length ? 0 : singleQ}</p>
                <p className="totalScore"> av totalt {lql} poeng</p>
            </fieldset>
        : null
        )
} 