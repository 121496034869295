import Card from "./Card";

export default function CourseContent({activeLesson, slug, course}){
    const startLesson = (lessonid) => {
        console.log(lessonid);
    }
    return(
        <><h1 key={activeLesson._id}>
            <span className="activeCourseTitle">
                <a href={`/kurs/${slug}`}>{course?.title}</a>
            </span>{activeLesson.title}
        </h1>
        <p key={activeLesson.preAmble}>{activeLesson.preAmble}</p>
        <h2>Leksjoner</h2>
        <div className="cards">
        {activeLesson?.subLessons?.map(sublesson => <Card key={sublesson._id} body={sublesson.body} title={sublesson.title} desc={sublesson.preAmble} url={`/kurs/${slug}/${activeLesson.slug.current}/${sublesson.slug.current}`} />)}    
        </div>
        </>
        
        
    )
}