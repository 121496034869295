import Answer from './Answer'

const letters = Array.from('abcdefghijklmnopqrstuvwxyz')

export default function Answers({
  answers,
  updateAnswer,
  checkedAnswer,
  isFail,
  results,
  question
}) {
  return (
    <fieldset>
        {answers?.map((answer, index) => (
          <Answer
            key={answer?._key}
            updateAnswer={updateAnswer}
            checkedAnswer={checkedAnswer}
            isFail={isFail}
            answer={answer} 
            results={results} 
            question={question}
          />
        ))}
    </fieldset>
  )
}