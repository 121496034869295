import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { fetchQuiz } from "../lib/services/courseService"
import ReactSyntaxHighlighter from "react-syntax-highlighter";
import { obsidian } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import Answers from './Answers'
import { fetchProgression, storeProgression } from "../lib/services/progressService";
import client from "../lib/sanityClient";

export default function Quiz({course, lesson, user}) {
    const [quiz, setQuiz] = useState([])
    const [answers, setAnswers] = useState([])
    const {quizSlug} = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [userProgression, setUserProgression] = useState([])

    const question = +searchParams.get('question') || 1
    const currentQuestion = quiz?.questions?.[question - 1]
    const checkedAnswer = answers[question-1]
    const isFail = !checkedAnswer?.correct
    const results = quiz?.results?.[0]?.quizscore ? JSON.parse(quiz?.results?.[0]?.quizscore) : false

    const getQuiz = async (quizSlug) => {
        const data = await fetchQuiz(quizSlug, user)
        setQuiz(data[0])
        if(data[0]?.results?.[0]?.quizscore) {
            setAnswers(JSON.parse(data[0]?.results?.[0]?.quizscore))
        }
    }
    useEffect(() => {
        getQuiz(quizSlug)
        getUserProgression(user)
    }, [quizSlug])

    const updateAnswer = (answer) => {
        setAnswers([
            ...answers.slice(0, question - 1),
            answer,
            ...answers.slice(question)
        ])
    }

    const done = searchParams.get('done')
    const isDone = (answers?.length === quiz?.questions?.length || (results && answers?.length === quiz?.questions?.length - 1)) && question >= quiz?.questions?.length

    const createProgression = async (currentQuiz, currentQuizscore) => {
        try{
            await storeProgression(course, lesson, "N/A", currentQuiz, user, currentQuizscore, 1)
        }
        catch(error) {
            throw new Error(error)
        }
    }

    const getUserProgression = async (user) =>{
        const userdata = await fetchProgression(user)
        setUserProgression(userdata)
    }
    console.log(userProgression)
    const userQuizProgress = userProgression.filter((field) => field?.quiz?.toString() === quiz?._id?.toString())
    const userQuizUpdateID = userQuizProgress[0]?._id
    const userQuizTries = userQuizProgress[0]?.quiztries
    const newUserQuizTries = Number(userQuizTries) + 1

    console.log(userQuizUpdateID + "/" + userQuizTries + "/" + newUserQuizTries)

    const progress = async () => {
        if (isDone) {
                //await createPlay({ email, quizId: quiz.id })
                setSearchParams(`?done=true`) 
                if(userQuizUpdateID !== undefined) { 
                    await client
                    .patch(userQuizUpdateID) // Document ID to patch
                    .set({quizscore: JSON.stringify(answers), quiztries: newUserQuizTries}) // Shallow merge
                    .commit() // Perform the patch and return a promise
                    .then((updatedQuizscore) => {
                        console.log('Quizscore updated!')
                    })
                    .catch((err) => {
                        console.error('Oh no, the update failed: ', err.message)
                    })
                } else {
                    createProgression(quiz._id, JSON.stringify(answers))
                }
            
        } else {
          setSearchParams(`?question=${question + 1}`)
        }
    }

    const handleProgress = () => {
        // TODO: If want to handle fail later we can not do it like we do now
        //if (isFail) return
        progress()
    }

    

    const progressWidth = done ? 1 : (100 / quiz?.questions?.length) * (quiz?.questions?.length - (question - 1))
    return (
        <div>
        <h1>{quiz.title}</h1>
        
        {
        done ?
            <>
            <p>Du har fullført quizen! Du fikk {answers?.filter(a => a.correct).length} av {quiz?.questions?.length} riktige svar.</p>
            </>
         : 
            <>
            <div className="progress">
            {!isDone ? <p>Spørsmål {question} av {quiz?.questions?.length}</p> : null }
            <div className="quizProgress">
                <div style={{width: `${progressWidth}%`}}></div>
            </div>
        </div>
            <p>{currentQuestion?.title}</p>
            {currentQuestion?.code ? 
                <ReactSyntaxHighlighter langauge={currentQuestion?.code.language} style={obsidian}>
                    {currentQuestion?.code.code}
                </ReactSyntaxHighlighter>
            : null }
            <Answers
                    answers={currentQuestion?.answers}
                    checkedAnswer={checkedAnswer}
                    isFail={isFail}
                    updateAnswer={updateAnswer} 
                    results={results} 
                    question={question} />
            {(checkedAnswer || results) && question <= quiz?.questions?.length ? (
                <button className="btn btn-default btn-right" type="button" onClick={handleProgress}>{isDone ? 'Fullfør' : 'Neste'}</button>
            ) : null}
            </>
        }
        </div>
    )
}