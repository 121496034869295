import React from "react"

export default function PanoptoEmbed(embedurl) {
    const urlReplaceEmbed = embedurl["embedurl"].toString().replace("Viewer","Embed")
    const urlComplete = urlReplaceEmbed + "&autoplay=false&offerviewer=true&showtitle=true&showbrand=true&captions=false&interactivity=all"

    return(
        <div className="video">
            <iframe src={urlComplete} title={embedurl} height="405" width="720" allowfullscreen></iframe>
        </div>
    )
}