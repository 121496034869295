import 'normalize.css';
import './css/main.css';
import {Route, Routes} from 'react-router-dom';
import { useLocalStorage } from './functions/LocalStorage';
import Signin from './components/Signin';
import Layout from './layout/Layout';
import Header from './layout/Header';
import Courses from './components/Courses';
import Course from './components/Course';
import { useState } from 'react';

function App() {
  const [user, setUser] = useLocalStorage('webtricksUser', [])


  const logOut = () => {
    setUser([])
    setTimeout(() => {
      window.location.href="/"
    }, 500)
  }
  return user.length > 0 ? (
   
    <div id="webtricks">
    <Header user={user} logOut={logOut} />
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Signin user={user} setUser={setUser} />} />
        <Route path="/kurs">
          <Route index element={<Courses />} />
          <Route path=":slug" element={<Course user={user}/>}>
            <Route path=":lessonSlug">
              <Route path=":subLessonSlug" element={<Course />} />
              <Route path="quiz/:quizSlug" element={<Course />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
    </div>
  ) :(
    <div id="webtricks">
    <Header user={user} logOut={logOut} />
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Signin user={user} setUser={setUser} />} />
      </Route>
    </Routes>
    </div>
  )
}

export default App;
