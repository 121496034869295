import { fetchAllCourses } from "../lib/services/courseService"
import {useEffect, useState} from 'react'
import Card from "./Card"

export default function Courses() {
    const [courses, setCourses] = useState([])

    const getAllCourses = async () => {
        const data = await fetchAllCourses()
        setCourses(data)
    }

    useEffect(() => {getAllCourses()}, [])



    return (
        <>
        <h1>Kurs</h1>
        <section id="courses">
            {
                courses?.map((course) => <Card key={course?._id} title={course?.title} desc={course.description} url={`/kurs/${course?.slug.current}`} />)
            }
        </section>
        </>
    )
}