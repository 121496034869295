export default function Answer({
    answer,
    updateAnswer,
    checkedAnswer,
    isFail,
    results,
    question
  }) {
    const isChecked = () => checkedAnswer?._key ? checkedAnswer?._key === answer?._key : results[question-1]?._key === answer?._key
    
    return (
      <div className="group">
        <input
          id={answer?._key}
          type="radio"
          name="answer"
          value={answer?.title}
          className=""
          onChange={() => updateAnswer(answer)}
          checked={isChecked()}
        />
        <label
          htmlFor={answer?._key}
          className={`answer-label ${
            isChecked()
              ? 'not-selectable'
              : 'selectable'
          } checked-green ${
            isChecked() && isFail ? 'checked-red' : ''
          }`}
        >

          {answer?.title}
        </label>
      </div>
    )
  }