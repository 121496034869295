import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCourse } from "../lib/services/courseService";
import CourseContent from "./CourseContent";
import CourseTitle from "./CourseTitle";
import Icon from "./layouts/Icon";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import { xonokai } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import {PortableText} from '@portabletext/react'
import Quiz from "./Quiz";
import { useNavigate } from "react-router-dom";
import { fetchQuiz } from "../lib/services/courseService";
import { fetchProgression } from "../lib/services/progressService";
import ProgressBar from "./ProgressBar";
import PanoptoEmbed from "./layouts/PanoptoEmbed";
import urlFor from "../functions/UrlFor";
import {parseOutline, TableOfContents} from "./layouts/TableOfContents";
import Card from "./Card";
import client from "../lib/sanityClient";
import QuizCalcution from "./QuizCalculation";


export default function Course({user}) {
    const [loading, setLoading] = useState(false)
    const [course, setCourse] = useState([])
    const [tocActive, setTocActive] = useState(true)
    
    const [userQuizes, setUserQuizes] = useState([])
    const {slug,lessonSlug,subLessonSlug,quizSlug=null} = useParams()
    const reDirect = useNavigate()
    let progScore = 0
   
    const getQuizProgression = async (user) =>{
        const data = await fetchProgression(user[0])
        setUserQuizes(data)
    }
    //console.log("USERQUIZES")
    //console.log(userQuizes)
    const [totalQuizScore, setTotalQuizScore] = useState(0)
    const [totalQuizQuestions, setTotalQuizQuestions] = useState(0)

    //Lager en ny array med json-stringifiserte objecter
    const userProgStringified = []
    //userProg.map(row => userProgStringified.push(JSON.stringify(row)))
    const uniqueUserProgStringified = [...new Set(userProgStringified)]
    const uniqueUserProg = []
    uniqueUserProgStringified.map(row => uniqueUserProg.push(JSON.parse(row)))
    //console.log("UNIQUE USERPROG")
    //console.log(uniqueUserProg)
    //filtrerer vekk det som ikke har sub leksjoner
    const completedSub = uniqueUserProg.filter(lesson => lesson.sublesson !== "N/A" && "n/a")
    //filtrerer og plusser på når en subleksjon er fullført 
    completedSub.filter(sub => sub.course === course._id).map((score) => score.sublesson.length > 0 ? progScore++ : null)
    //filtrerer på kurs og gjennomført quiz
    const quizScoreArray = uniqueUserProg.filter(sub => sub.course === course._id).filter(score => score?.quiz !== "n/a").map((quiz) => quiz?.quizscore)
    let quizUserPointsAdded = 0
    quizScoreArray.map((item) => JSON.parse(item).filter(corr => corr.correct)).map((arr) => arr.length).map((a) => quizUserPointsAdded += a)
    // antall poeng brukeren har fått fra kurs og antall riktige svar fra quiz
    let totalScore = quizUserPointsAdded + progScore
    //Antall subleksjoner
    let numOfSub = 0
    //sammler antall sub leksjoner
    course?.lessons?.map((sub => sub.subLessons))?.map((num) => num.length).map((n) => numOfSub += n)
    //antall quiz spm
    let numOgQuizQue = 0
    //samler antall quiz spm
    course?.lessons?.map((lesson) => lesson?.quiz)?.map((q) => typeof q[0]?.questions.length !== "undefined" ? numOgQuizQue += parseInt(q[0]?.questions?.length) : null)
    //summerer antall sub leksjoner og quiz spm
    let totalPoints = numOgQuizQue + numOfSub
    //const userScore = completedSub.map((score) => score.sublesson.length > 0 ? progScore++ : null)
    const flipTocActive = () => {
        setTocActive(!tocActive)
    }

    const completeLessonOrQuiz = async (lesson, sublesson, quiz = "n/a") => {
        //Insert username, courseid, lession, lessionid

        /*if(sublesson.complete <= 0) {
            try {
                await storeProgression(course._id,lesson._id,sublesson._id,quiz,user[0])
             
            }
            catch(error){
                console.log("Insert malfunctioning" + error)
            }
        }*/

        if(sublesson?.completedUsers === null || !sublesson?.completedUsers.includes(user[0])) {
            //console.log("New Patch code")
            await client.patch(sublesson._id)
            .setIfMissing({completedUsers: []})
            .insert('after', 'completedUsers[-1]', [user[0]]).commit()
        }
        
        //Send:
        /*sendToNext(sublesson.subLessonOrder < lesson.subLessons.length ?
            `/kurs/${slug}/${lessonSlug}/${lesson.subLessons[sublesson.subLessonOrder].slug.current}` :
             `/kurs/${slug}/${lessonSlug}/quiz/${lesson.quiz[0].slug.current}`)*/
    }
    const getQuiz = async (quizSlug) => {
        const data = await fetchQuiz(quizSlug, user)
    }
    const sendToNext = (slug) =>{
        reDirect(slug)
    }
    const getCourse = async (slug) => {
        const data = await fetchCourse(slug, user[0])
        setCourse(data[0])
    }
    useEffect(() => {
        setLoading(true)
        getCourse(slug)
        setLoading(false)
        getQuiz(quizSlug)
        getQuizProgression(user)
    }, [slug, lessonSlug, subLessonSlug, quizSlug, user])


    const PortableTextComponents = {
        block: {
            h2: ({node, children}) => <h2 id={`h${node._key}`}>{children}</h2>,
            h3: ({node, children}) => <h3 id={`h${node._key}`}>{children}</h3>,
            h4: ({node, children}) => <h4 id={`h${node._key}`}>{children}</h4>,
        },
        types: {
            image: ({ value }) => {
                if(!value?.asset?._ref) {
                    return null
                }
                return(
                    <img 
                        alt={value.alt || ' '} 
                        loading="lazy" 
                        src={urlFor(value).fit('max').auto('format')}
                    />
                )
            },
            code: ({value}) => {
                if(!value?.code) {
                    return null
                }
                return(
                    <SyntaxHighlighter language={value.language} style={xonokai} showLineNumbers={true} wrapLongLines={true}>
                        {value.code}
                    </SyntaxHighlighter>
                )
            },
            panoptoVideo: ({value}) => {
                if(!value?.embedcode) {
                    return null
                }
                
                return(
                    <PanoptoEmbed embedurl={value.embedcode} />
                )
            },
            fieldset: ({value}) => {
                if(!value.title) {
                    return null
                }

                return(
                    <fieldset>
                        <legend>{value.title}</legend>
                        <p>{value.content}</p>
                    </fieldset>
                )
            }
        }
    }

    let width = (100/totalPoints) * totalScore
    return (
        <div id="course">
            <h3>{course.title}</h3>
            <ProgressBar bgcolor={"#347E84"} completed={width} />
            {
                loading ? 
                <Icon icon={"fi-spinner fi-pulse"} /> : 
                (
                    <>
                        <section id="courseContent">
                            <aside className="lessonMenu">
                                <h3>Leksjoner</h3>
                                
                                <ul>
                                {
                                    //Liste opp alle leksjoner med link
                                    course?.lessons?.map(
                                        (lesson) => 
                                        <li key={lesson._id} className={lessonSlug === lesson.slug.current ? "activeLesson" : "lesson"}>
                                            <a href={`/kurs/${slug}/${lesson.slug.current}`} >
                                            {lesson.title}
                                            </a>
                                            {lesson?.subLessons?.length > 0 ? <ul>{lesson?.subLessons?.map(
                                                (subLesson => <li key={subLesson._id}>
                                                    <a href={`/kurs/${slug}/${lesson.slug.current}/${subLesson.slug.current}`}>
                                                        <Icon icon={"fi-angle-right"} /> {subLesson?.title}
                                                        {subLesson?.completedUsers?.includes(user[0]) ? <Icon icon={"fi-check"} direction="right" /> : null}
                                                    </a>
                                                </li>)
                                            )}</ul> : null}
                                            {lesson?.quiz?.length > 0 ? <ul>{lesson?.quiz?.map(
                                                (q => <li key={q._id}>
                                                    <a href={`/kurs/${slug}/${lesson.slug.current}/quiz/${q.slug.current}`}>
                                                        <Icon icon={"fi-question"} /> {q?.title}
                                                    </a>
                                                </li>)
                                            )}</ul> : null} 
                                        </li>
                                    )
                                   
                                }
                                </ul>
                            </aside>
                            <article className="lessonContent">
                                {
                                    quizSlug ? <Quiz course={course._id} user={user[0]} lesson={lessonSlug} /> : 
                                    //Hvis lessonSlug, hent riktig leksjon
                                    subLessonSlug ? 
                                    course?.lessons?.filter(
                                        lesson => lesson.slug.current === lessonSlug).map(
                                            activeLesson => activeLesson.subLessons.filter(
                                                subLesson => subLesson.slug.current === subLessonSlug).map(
                                                    activeSubLesson => 
                                                    <div key={activeLesson._id}>
                                                        <CourseTitle slug={slug} course={course} activeSubLesson={activeSubLesson} activeLesson={activeLesson} lessonSlug={lessonSlug}/>
                                                        <div className="toc">
                                                            {tocActive ? 
                                                            <>
                                                                <button onClick={flipTocActive}><Icon icon={"fi-angle-up"} /></button>
                                                                <p>I denne leksjonen lærer du mer om:</p>
                                                                <TableOfContents outline={parseOutline(activeSubLesson.body)} />
                                                            </> :
                                                            <>
                                                                <button onClick={flipTocActive}><Icon icon={"fi-angle-down"} /></button>
                                                                <p>Åpne snarveier</p>
                                                            </>
                                                            }
                                                        </div>
                                                        <p className="preamble">{activeSubLesson.preAmble}</p>
                                                        <PortableText value={activeSubLesson.body} components={PortableTextComponents} />
                                                        
                                                    </div>
                                        )
                                    )
                                     : (lessonSlug && !subLessonSlug) 
                                    ?
                                    (
                                        course?.lessons?.filter(lesson => lesson.slug.current === lessonSlug).map(activeLesson => 
                                            <CourseContent key={activeLesson._id} activeLesson={activeLesson} slug={slug} course={course}/>
                                        )
                                    )
                                    : 
                                        <>
                                        <h1 className="contentTitle">{course?.title}</h1>
                                        <p>{course?.description}</p>
                                        <section id="quizOverview">
                                            <h2>Quizer i dette kurset</h2>
                                            {course?.lessons?.map(
                                                lesson => 
                                                    <QuizCalcution 
                                                        key={lesson._id} 
                                                        userQuizes={userQuizes} 
                                                        lesson={lesson} 
                                                        tqs={totalQuizScore} 
                                                        setTqs={setTotalQuizScore} 
                                                        tqq={totalQuizQuestions} 
                                                        setTqq={setTotalQuizQuestions}>
                                                    </QuizCalcution>
                                                    
                                                )
                                            }
                                            
                                        </section>
                                        <h2>Leksjoner:</h2>
                                        {course?.lessons?.map((lesson) => <Card key={lesson._id} title={lesson.title} desc={lesson.preAmble} url={`/kurs/${course?.slug.current}/${lesson.slug.current}`} />)}
                                    </>
                                }
                            </article>
                        </section>
                        
                    </>
                )
            }
            
        </div>
    )
}