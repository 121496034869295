/*
 * Card.js - generic card component. Recieves props to generate:
 * title: used as title
 * image: image url, used as illustration. Placeholderimage if not submitted
 * desc: description, short preamble
 * url: url to send user to new site/page/content
 * Can also use <TableOfContents to supply topics in related article
 */

import Button from "./layouts/Button";
import { parseOutline, TableOfContents } from "./layouts/TableOfContents";

export default function Card({title,image,desc,url, body = null}) {
    return (
        <article className="card">
            {title ? url ? <h2><a href={url}>{title}</a></h2> : <h2>{title}</h2> : null}
            {desc ? <p>{desc}</p> : null}
            {body ? <><p>I denne leksjonen lærer du mer om</p><TableOfContents baseUrl={url} outline={parseOutline(body)} /></> : null}
            {url ? <div className="card-button"><Button url={url} icon={"fi-angle-right"} /></div> : null}
        </article>
    )
}