export default function ProgressBar({bgcolor, completed}){
    const containerStyles = {
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        marginTop: 20 
      }
    
      const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right'
      }
    
      const labelStyles = {
        padding: 10,
        color: 'white',
        fontWeight: 'normal',
        display: 'block'
      }

    return(
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${parseInt(completed)}%`}</span>
      </div>
    </div>
    )

}