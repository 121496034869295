import client from "../sanityClient";

export const storeProgression = async (course, lesson, sublesson, quiz, user, quizscore, quiztries) => {
    try {
        await client.create({
            _type: "progression",
            course, lesson, sublesson, quiz, user, quizscore, quiztries
        })
    }
    catch(error){
        throw new Error(error)
    }
}

export const storeCourseComplete = async (course, user) => {
    try {
        //client.patch(course).set({'completedUsers[-1]':user}).commit().then(r => {console.log(r;)})
        client.patch({
            "id": course,
            "insert": {
                "after": "completedUsers[-1]",
                "items": [{ user }]
            }
        })
    }
    catch(error) {
        throw new Error(error)
    }
}

/*client.patch('296499fa-ec36-45ca-b1bd-db0e356b3923')
.set({'alternatives[0].title.nn':'Yep', })
.commit()
.then(r => {console.log(r); })*/

/*const handleAction = (field) =&gt; {
    const patches = insert(
      [{ _type: field.name }],
      'after',
      [-1]
    )
    const patchFrom = PatchEvent.from(patches).prepend(setIfMissing([]))
  
    onChange(patchFrom)
  }*/

export const fetchProgression = async (user) =>{
    const data = await client.fetch(`*[_type == "progression" && user == $user] | order(_createdAt desc) {_id, course, lesson, quiz, quizscore, sublesson, user, quiztries}`,{user})
    return data
}

