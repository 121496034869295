import client from "../sanityClient";

export const fetchAllCourses = async () => {
    const data = await client.fetch(`*[_type == "course"]{...}`)
    return data
}

/*export const fetchCourse = async (slug, user) => {
    const data = await client.fetch(
        `*[_type == "course" && slug.current == $slug]{
            _id, title, description, slug,
            "lessons": *[_type == "lesson" && course._ref == ^._id]{
                _id, title, slug, preAmble, body, lessonOrder,
                "subLessons": *[_type == "subLesson" && lesson._ref == ^._id]{
                    _id, title, slug, preAmble, body, subLessonOrder,completedUsers
                    "complete": count(*[_type == "progression" && user == $user && sublesson == ^._id])
                } | order(subLessonOrder),
                "quiz": *[_type == "quiz" && lesson._ref == ^._id] {
                    _id, slug, title, questions
                }
            } | order(lessonOrder), 
        }`, {slug, user}
    )
    return data
}*/

export const fetchCourse = async (slug, user) => {
    const data = await client.fetch(
        `*[_type == "course" && slug.current == $slug]{
            _id, title, description, slug,
            "lessons": *[_type == "lesson" && course._ref == ^._id]{
                _id, title, slug, preAmble, body, lessonOrder,
                "subLessons": *[_type == "subLesson" && lesson._ref == ^._id]{
                    _id, title, slug, preAmble, body, subLessonOrder,completedUsers
                } | order(subLessonOrder),
                "quiz": *[_type == "quiz" && lesson._ref == ^._id] {
                    _id, slug, title, questions
                }
            } | order(lessonOrder), 
        }`, {slug, user}
    )
    return data
}

export const fetchQuiz = async (quizSlug, user) => {
    const data = await client.fetch(
        `*[_type == "quiz" && slug.current == $quizSlug]{_id, title, lesson, slug, questions,
        "results": *[_type == "progression" && user == $user && quiz == ^._id]{_id,quizscore}}`, {quizSlug, user}
    )
    return data
}