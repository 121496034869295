import React from "react" 
import { Link } from "react-router-dom"

export default function Header({user, logOut}) {
    return(
        <header>
            <h1 id="web-logo"><Link to="/">Webtricks LMS</Link></h1>
            <nav>
                <ul>
                    <li className="profile"><i className={`fi ${user.length > 0 ? "fi-person" : "fi-locked"}`} /> {user.length > 0 ? `${user}` : <Link to="/">Logg inn</Link>
} {user.length > 0 ? (
            <button className="logout" type="button" onClick={logOut}>
              Logg ut
            </button>
          ) : null}</li>
                </ul>
                
            </nav>
        </header>
    )
    
}